.page-template {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: linear-gradient(135deg, #fff 0%, #f6f9fc 100%);
}

/* Header Section */
.page-header {
  min-height: 40vh;
  padding: 120px 5% 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  background: linear-gradient(
    135deg,
    rgba(140, 48, 245, 0.05) 0%,
    rgba(0, 242, 254, 0.05) 100%
  );
}

.header-content {
  text-align: center;
  max-width: 800px;
  position: relative;
  z-index: 2;
}

.user-email {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 1rem;
}

.header-content h1 {
  font-size: 3.5rem;
  margin-bottom: 1.5rem;
  background: linear-gradient(90deg, #8c30f5 0%, #b066ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 1.1;
  font-weight: 800;
}

.header-content p {
  font-size: 1.2rem;
  color: #666;
  line-height: 1.6;
  margin: 0 auto;
  max-width: 600px;
}

.animated-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}

.medical-symbol {
  position: absolute;
  opacity: 0.1;
  animation: float 6s ease-in-out infinite;
}

/* Main Content Section */
.page-content {
  flex: 1;
  padding: 60px 5%;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
}

/* Footer Section */
.page-footer {
  background: #ffffff;
  padding: 2rem 5%;
  border-top: 1px solid rgba(140, 48, 245, 0.1);
  margin-top: auto;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

.footer-sections {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1.5rem;
  flex-wrap: wrap;
  gap: 2rem;
}

.footer-links {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
}

.footer-links a {
  color: #666;
  text-decoration: none;
  font-size: 0.9rem;
  transition: color 0.3s ease;
}

.footer-links a:hover {
  color: #8c30f5;
}

.footer-social {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  margin-bottom: 1.5rem;
}

.footer-social a {
  color: #666;
  transition: color 0.3s ease;
}

.footer-social a:hover {
  color: #8c30f5;
}

.footer-contact {
  text-align: left;
  color: #666;
  font-size: 0.9rem;
}

.footer-contact p {
  margin: 0.5rem 0;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.footer-contact i {
  color: #8c30f5;
  font-size: small;
}

.footer-social i {
  font-size: 1rem;
}

.footer-bottom {
  color: #666;
  font-size: 0.9rem;
}

/* Responsive Design */
@media (max-width: 768px) {
  .page-header {
    min-height: 30vh;
    padding: 100px 5% 40px;
  }

  .header-content h1 {
    font-size: 2.5rem;
  }

  .header-content p {
    font-size: 1.1rem;
  }

  .page-content {
    padding: 40px 5%;
  }

  .footer-links {
    flex-wrap: wrap;
    gap: 1rem;
  }
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0px);
  }
}
