.about-page section {
  padding: 4rem 5%;
  max-width: 1200px;
  margin: 0 auto;
}

.our-story {
  text-align: left;
}

.our-story p {
  font-size: 1.1rem;
  line-height: 1.8;
  margin-bottom: 1.5rem;
}

.team-grid {
  display: grid;
  flex-direction: row !important;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
  flex-wrap: wrap;
}

.team-member {
  background: white;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  text-align: center;
  min-width: 300px;
}

.member-image {
  width: 200px;
  height: 200px;
  margin: 0 auto 1.5rem;
  border-radius: 50%;
  overflow: hidden;
  background: linear-gradient(
    135deg,
    rgba(140, 48, 245, 0.1) 0%,
    rgba(0, 242, 254, 0.1) 100%
  );
  padding: 8px;
}

.member-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.team-member h3 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: #1a1a1a;
}

.team-member h4 {
  color: #8c30f5;
  margin-bottom: 1rem;
}

.view-profile {
  color: #8c30f5;
  margin-top: 1rem;
  font-weight: 500;
}

.team-member:hover .view-profile {
  text-decoration: underline;
}

.values-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.value-badge {
  background: white;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.value-badge i {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  background: linear-gradient(90deg, #00f2fe 0%, #4facfe 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.value-badge h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #1a1a1a;
}

@media (max-width: 768px) {
  .about-hero h1 {
    font-size: 2.5rem;
  }

  section {
    padding: 3rem 2rem;
  }

  .team-grid,
  .values-grid {
    grid-template-columns: 1fr;
  }

  .team-grid {
    flex-direction: row;
  }

  .member-image {
    width: 150px;
    height: 150px;
  }
}
