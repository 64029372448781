.dataroom-hero {
  min-height: 50vh;
  padding: 120px 5% 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  background: linear-gradient(
    135deg,
    rgba(140, 48, 245, 0.05) 0%,
    rgba(0, 242, 254, 0.05) 100%
  );
  margin-left: 250px;
  width: calc(100% - 250px);
}

.dataroom-hero .hero-content {
  text-align: center;
  max-width: 800px;
  position: relative;
  z-index: 2;
}

.dataroom-hero h1 {
  font-size: 3.5rem;
  margin-bottom: 1.5rem;
  background: linear-gradient(90deg, #8c30f5 0%, #b066ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 1.1;
  font-weight: 800;
}

.dataroom-hero p {
  font-size: 1.2rem;
  color: #666;
  line-height: 1.6;
  margin: 0 auto 2rem;
  max-width: 600px;
}

.hero-stats {
  display: flex;
  justify-content: center;
  gap: 4rem;
  margin-top: 3rem;
}

.hero-stats .stat {
  text-align: center;
}

.hero-stats .stat-number {
  font-size: 2.5rem;
  font-weight: 800;
  background: linear-gradient(90deg, #8c30f5, #b066ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: block;
  margin-bottom: 0.5rem;
}

.hero-stats .stat-label {
  font-size: 1.1rem;
  color: #666;
}

.finance-symbol {
  position: absolute;
  opacity: 0.1;
  animation: float 6s ease-in-out infinite;
}

.investor-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  height: 100vh;
  background: white;
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.05);
  z-index: 100;
}

.menu-logo {
  padding: 0 2rem;
  margin-bottom: 0.5rem;
}

.menu-logo img {
  width: 180px;
  height: auto;
}

.menu-subtitle {
  padding: 0 2rem;
  margin-bottom: 2rem;
  color: #666;
  font-size: 0.9rem;
  font-style: italic;
}

.menu-item {
  color: #666;
  text-decoration: none;
  font-weight: 500;
  padding: 1rem 2rem;
  transition: all 0.3s ease;
  border-left: 3px solid transparent;
}

.menu-item:hover {
  color: #8c30f5;
  background: rgba(140, 48, 245, 0.05);
  border-left-color: #8c30f5;
}

.dataroom-container {
  margin-left: 250px;
  padding: 2rem 4rem;
  width: calc(100% - 250px);
}

.welcome-section {
  text-align: left;
  margin-bottom: 4rem;
}

.welcome-section h2 {
  font-size: 2.2rem;
  color: #1a1a1a;
  margin-bottom: 1rem;
}

.split-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  margin-bottom: 4rem;
}

.problem-section {
  margin-bottom: 0;
  padding: 0;
}

.problem-content {
  width: 100%;
  background: white;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
}

.problem-highlight {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 2rem;
  padding: 1.5rem;
  background: linear-gradient(
    135deg,
    rgba(140, 48, 245, 0.05) 0%,
    rgba(0, 242, 254, 0.05) 100%
  );
  border-radius: 10px;
}

.problem-highlight i {
  font-size: 2rem;
  color: #8c30f5;
  background: none;
  -webkit-background-clip: initial;
  -webkit-text-fill-color: initial;
}

.problem-highlight p {
  font-size: 1.3rem;
  color: #1a1a1a;
  font-weight: 500;
  margin: 0;
  line-height: 1.5;
}

.problem-description {
  color: #666;
}

.problem-description p {
  line-height: 1.8;
  margin-bottom: 1.5rem;
}

.problem-description p:last-child {
  margin-bottom: 0;
}

.stat {
  color: #8c30f5;
  font-weight: 600;
}

.welcome-subtitle {
  color: #666;
}

.team-section,
.company-overview-section,
.advisors-section,
.metrics-section,
.investor-documents,
.product-section {
  margin-bottom: 4rem;
}

.team-grid,
.overview-grid,
.advisors-grid,
.product-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-top: 2rem;
}

.metrics-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  margin-top: 2rem;
}

.team-member,
.overview-item,
.product-item {
  background: white;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease;
  text-align: center;
}

.metric-item {
  background: linear-gradient(
    135deg,
    rgba(140, 48, 245, 0.05) 0%,
    rgba(0, 242, 254, 0.05) 100%
  );
  padding: 2rem;
  border-radius: 15px;
  text-align: center;
  transition: transform 0.3s ease;
}

.metric-value {
  font-size: 2.5rem;
  font-weight: bold;
  background: linear-gradient(90deg, #8c30f5 0%, #b066ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 1rem 0;
}

.overview-item:hover,
.product-item:hover,
.metric-item:hover {
  transform: translateY(-5px);
}

.overview-item i,
.product-item i,
.metric-item i {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  background: linear-gradient(90deg, #8c30f5 0%, #b066ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.overview-item h3,
.product-item h3 {
  font-size: 1.3rem;
  margin-bottom: 1rem;
  color: #1a1a1a;
}

.overview-item p,
.product-item p {
  color: #666;
  line-height: 1.6;
}

h2 {
  font-size: 2rem;
  color: #1a1a1a;
  margin-bottom: 2rem;
  text-align: left;
}

.document-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
}

.document-item {
  background: white;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease;
  text-align: left;
}

.pitch-deck {
  grid-column: 1 / -1;
}

.pitch-deck-section {
  background: white;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
}

.pitch-deck-container {
  margin-top: 1rem;
  width: 100%;
  height: calc(100% - 65px);
  border-radius: 8px;
  overflow: hidden;
}

.pitch-deck-container iframe {
  width: 100%;
  height: 100%;
}

.document-item:hover {
  transform: translateY(-5px);
}

.document-item i {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  background: linear-gradient(90deg, #8c30f5 0%, #b066ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.document-item h3 {
  font-size: 1.3rem;
  margin-bottom: 0.5rem;
  color: #1a1a1a;
}

.document-item p {
  color: #666;
  margin-bottom: 1.5rem;
}

.download-button {
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  border: none;
  border-radius: 30px;
  background: linear-gradient(90deg, #00f2fe 0%, #4facfe 100%);
  color: white;
  cursor: pointer;
  transition: transform 0.3s ease;
  width: 100%;
}

.download-button:hover {
  transform: translateY(-2px);
}

@media (max-width: 1024px) {
  .overview-grid,
  .metrics-grid,
  .product-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

.team-member {
  text-align: left;
  position: relative;
  background: linear-gradient(145deg, #ffffff, #f6f9fc);
  border: 1px solid rgba(140, 48, 245, 0.1);
  transition: all 0.4s ease;
  overflow: hidden;
}

.team-member::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: linear-gradient(90deg, #8c30f5, #b066ff);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.team-member:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 35px rgba(140, 48, 245, 0.15);
}

.team-member:hover::before {
  opacity: 1;
}

.member-image {
  width: 180px;
  height: 180px;
  margin: 0 auto 1.5rem;
  border-radius: 50%;
  overflow: hidden;
  border: 4px solid white;
  box-shadow: 0 8px 20px rgba(140, 48, 245, 0.15);
  transition: transform 0.4s ease;
}

.team-member:hover .member-image {
  transform: scale(1.05);
}

.member-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.team-member h3 {
  font-size: 1.8rem;
  margin-bottom: 0.5rem;
  color: #1a1a1a;
  background: linear-gradient(90deg, #1a1a1a, #333);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.team-member h4 {
  font-size: 1.2rem;
  background: linear-gradient(90deg, #8c30f5, #b066ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 1.2rem;
  font-weight: 600;
}

.team-member p {
  color: #666;
  line-height: 1.8;
  margin-bottom: 1.5rem;
  font-size: 1.1rem;
}

.social-links {
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 1.2rem;
  margin-top: 1rem;
  border-top: 1px solid rgba(140, 48, 245, 0.1);
}

.social-links a {
  color: #8c30f5;
  text-decoration: none;
  transition: all 0.3s ease;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 14px;
  border-radius: 50%;
  background: linear-gradient(145deg, #ffffff, #f6f9fc);
  box-shadow: 5px 5px 10px rgba(140, 48, 245, 0.1),
    -5px -5px 10px rgba(255, 255, 255, 0.8);
}

.social-links a:hover {
  transform: translateY(-3px);
  box-shadow: 0 8px 15px rgba(140, 48, 245, 0.2);
  background: linear-gradient(90deg, #8c30f5, #b066ff);
}

.social-links a:hover i {
  color: white;
  background: none;
  -webkit-background-clip: initial;
  -webkit-text-fill-color: initial;
}

.social-links i {
  font-size: 1.4rem;
  background: linear-gradient(90deg, #8c30f5, #b066ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.3s ease;
}

.advisors-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  margin-top: 2rem;
}

.advisor-item {
  background: white;
  padding: 1.5rem;
  border-radius: 12px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.advisor-item .member-image {
  width: 80px;
  height: 80px;
  min-width: 80px;
  margin: 0;
}

.advisor-info {
  flex: 1;
  text-align: left;
}

.advisor-item h3 {
  font-size: 1.1rem;
  margin-bottom: 0.3rem;
  color: #1a1a1a;
}

.advisor-item p {
  color: #666;
  font-size: 0.9rem;
  line-height: 1.3;
  margin: 0;
}

@media (max-width: 1024px) {
  .advisors-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

.updates-section {
  margin-bottom: 4rem;
}

.updates-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
}

.update-item {
  background: white;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease;
  border: 1px solid rgba(140, 48, 245, 0.1);
}

.update-item:hover {
  transform: translateY(-5px);
}

.update-date {
  color: #8c30f5;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.update-item h3 {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  color: #1a1a1a;
}

.update-highlights {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  margin-bottom: 1.5rem;
  padding: 1rem;
  background: linear-gradient(145deg, #ffffff, #f6f9fc);
  border-radius: 10px;
}

.highlight {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.highlight i {
  font-size: 1.2rem;
  color: #8c30f5;
  background: none;
  -webkit-background-clip: initial;
  -webkit-text-fill-color: initial;
}

.highlight span {
  font-size: 0.9rem;
  color: #666;
}

.update-item p {
  color: #666;
  line-height: 1.6;
  margin-bottom: 1.5rem;
}
.logo {
  width: 450px;
  padding-bottom: 20px;
  padding-top: 20px;
  max-width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .dataroom-container {
    padding: 1rem;
  }

  .overview-grid,
  .metrics-grid,
  .product-grid,
  .document-grid {
    grid-template-columns: 1fr;
  }

  .welcome-section h2 {
    font-size: 1.8rem;
  }

  .metric-value {
    font-size: 2rem;
  }

  .update-highlights {
    grid-template-columns: 1fr;
  }
}
