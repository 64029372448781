.business-card-container {
  max-width: 600px;
  margin: 2rem auto;
  padding: 1rem;
}

.business-card {
  background: white;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  padding: 2rem;
}

.card-header {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-bottom: 2rem;
}

.card-image {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  background: linear-gradient(
    135deg,
    rgba(140, 48, 245, 0.1) 0%,
    rgba(0, 242, 254, 0.1) 100%
  );
  padding: 4px;
}

.card-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.card-info h1 {
  font-size: 1.8rem;
  margin-bottom: 0.5rem;
  color: #1a1a1a;
}

.card-info h2 {
  font-size: 1.2rem;
  color: #8c30f5;
  margin-bottom: 0.5rem;
}

.company {
  color: #666;
  font-size: 1rem;
}

.contact-info {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
}

.contact-item {
  display: flex;
  align-items: center;
  gap: 1rem;

  background: #ffffff;
  border-radius: 8px;
  color: #1a1a1a;
  text-decoration: none;
  transition: all 0.3s ease;
}

.contact-item i {
  padding-top: 16px;
  padding-left: 16px;
}

.contact-item:hover {
  background: #e5e5e5;
  transform: translateX(5px);
}

.contact-item i {
  font-size: 1.2rem;
  color: #8c30f5;
}

.qr-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0;
  padding: 1rem;
  background: #f5f5f5;
  border-radius: 12px;
}

.qr-section p {
  margin-top: 1rem;
  color: #666;
}

.action-buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.action-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  background: #8c30f5;
  color: white;
  border-radius: 8px;
  text-decoration: none;
  transition: all 0.3s ease;
}

.action-button:hover {
  background: #7020d9;
  transform: translateY(-2px);
}

@media (max-width: 600px) {
  .card-header {
    flex-direction: column;
    text-align: center;
  }

  .action-buttons {
    grid-template-columns: 1fr;
  }
}
