.privacy-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 2rem;
}

.privacy-content {
  background: white;
  padding: 3rem;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.effective-date,
.effective-date-bottom {
  color: #666;
  margin-bottom: 2rem;
}

.effective-date-bottom {
  margin-top: 3rem;
  margin-bottom: 0;
  padding-top: 2rem;
  border-top: 1px solid rgba(140, 48, 245, 0.1);
}

.privacy-section {
  margin-bottom: 2.5rem;
}

.privacy-section:last-child {
  margin-bottom: 0;
}

.privacy-section h2 {
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
  text-align: left;
  color: #1a1a1a;
  background: linear-gradient(90deg, #8c30f5 0%, #b066ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.privacy-section p {
  line-height: 1.8;
  color: #333;
  margin-bottom: 1rem;
}

.privacy-section ul {
  list-style: none;
  padding-left: 0;
  margin-top: 1rem;
}

.privacy-section li {
  margin-bottom: 1rem;
  line-height: 1.6;
  position: relative;
  padding-left: 1.5rem;
}

.privacy-section li::before {
  content: "•";
  color: #8c30f5;
  position: absolute;
  left: 0;
}

.contact-info {
  background: rgba(140, 48, 245, 0.05);
  padding: 2rem;
  border-radius: 10px;
  margin-top: 1.5rem;
}

.contact-info a {
  color: #8c30f5;
  text-decoration: none;
  transition: color 0.3s ease;
}

.contact-info a:hover {
  color: #b066ff;
}

@media (max-width: 768px) {
  .privacy-container {
    padding: 1rem;
  }

  .privacy-content {
    padding: 2rem;
  }

  .privacy-section h2 {
    font-size: 1.5rem;
  }
}
