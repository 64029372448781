.waitlist-section {
  padding: 2rem 5%;
}

.waitlist-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  max-width: 1200px;
  margin: 0 auto;
  background: white;
  padding: 3rem;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.waitlist-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

@media (max-width: 768px) {
  .waitlist-section {
    padding: 0;
    margin: 0px;
    background: transparent;
    border-radius: 0px;
  }

  .waitlist-container {
    padding: 0rem !important;
    box-shadow: none;
    background-color: transparent;
    margin: 0px;
  }
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form-group label {
  font-weight: 500;
  color: #1a1a1a;
}

.form-group input,
.form-group select,
.form-group textarea {
  padding: 0.8rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  transition: border-color 0.3s ease;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
  border-color: #8c30f5;
  outline: none;
}

.waitlist-button {
  padding: 1rem;
  background: linear-gradient(90deg, #8c30f5 0%, #b066ff 100%);
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: transform 0.3s ease;
  margin-top: 1rem;
}

.waitlist-button:hover {
  transform: translateY(-2px);
}

.waitlist-info {
  padding: 2rem;
  background: linear-gradient(
    135deg,
    rgba(140, 48, 245, 0.03) 0%,
    rgba(0, 242, 254, 0.03) 100%
  );
  border-radius: 15px;
}

.waitlist-info h3 {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  color: #1a1a1a;
}

.waitlist-info ul {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.waitlist-info li {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: #666;
}

.waitlist-info i {
  font-size: 1.2rem;
  background: linear-gradient(90deg, #00f2fe 0%, #4facfe 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.waitlist-stats {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  margin-top: 2rem;
  padding-top: 2rem;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.stat {
  text-align: center;
}

.stat-number {
  display: block;
  font-size: 2rem;
  font-weight: bold;
  background: linear-gradient(90deg, #8c30f5 0%, #b066ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 0.5rem;
}

.stat-label {
  color: #666;
  font-size: 0.9rem;
}

@media (max-width: 768px) {
  .waitlist-container {
    grid-template-columns: 1fr;
    padding: 2rem;
    gap: 2rem;
  }

  .waitlist-info {
    padding: 1.5rem;
  }

  .waitlist-stats {
    grid-template-columns: 1fr 1fr;
  }
}
