.story-path-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden;
}


.story-character {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;
  transform: translateX(-100px);
  transition: opacity 0.5s ease;
}

.story-character.visible {
  opacity: 1;
}

.character-placeholder {
  width: 200px;
  height: 200px;
  background: rgba(140, 48, 245, 0.1);
  border-radius: 20px;
  margin-bottom: 1rem;
}

.story-character p {
  font-size: 1.2rem;
  color: #666;
  text-align: center;
  max-width: 200px;
}

/* Character positions */
.character-connect {
  top: 25vh;
  left: -200px;
}

.character-doctor {
  top: 50vh;
  right: -200px;
}

.character-family {
  top: 75vh;
  left: -200px;
}

.character-circle {
  top: 100vh;
  right: -200px;
}

/* Placeholder styles - replace these when you add your Lunacy illustrations */
.character-placeholder.connect {
  background: linear-gradient(135deg, rgba(140, 48, 245, 0.1), rgba(0, 242, 254, 0.1));
}

.character-placeholder.doctor {
  background: linear-gradient(135deg, rgba(0, 242, 254, 0.1), rgba(140, 48, 245, 0.1));
}

.character-placeholder.family {
  background: linear-gradient(135deg, rgba(140, 48, 245, 0.1), rgba(0, 242, 254, 0.1));
}

.character-placeholder.circle {
  background: linear-gradient(135deg, rgba(0, 242, 254, 0.1), rgba(140, 48, 245, 0.1));
}
