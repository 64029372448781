.menu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 5%;
  background: transparent;
  backdrop-filter: blur(8px);
  z-index: 1000;
}

.menu-brand a {
  text-decoration: none;
  display: flex;
  align-items: center;
}

.menu-logo {
  height: 40px;
  width: auto;
  object-fit: contain;
}

@media (max-width: 768px) {
  .menu-logo {
    height: 32px;
  }
}

.menu-items {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  gap: 2rem;
}

.menu-items li a {
  color: #1a1a1a;
  text-decoration: none;
  font-weight: 600;
  font-size: 0.95rem;
  transition: color 0.3s ease;
}

.menu-items li a:hover {
  color: #8c30f5;
}

.menu-toggle {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 21px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  color: #1a1a1a;
}

.menu-toggle span {
  width: 100%;
  height: 3px;
  background-color: rgb(29, 29, 29);
  transition: all 0.3s ease;
}

@media (max-width: 768px) {
  .menu-toggle {
    display: flex;
  }

  .menu-toggle.open span:first-child {
    transform: rotate(45deg) translate(6px, 6px);
  }

  .menu-toggle.open span:nth-child(2) {
    opacity: 0;
  }

  .menu-toggle.open span:last-child {
    transform: rotate(-45deg) translate(6px, -6px);
  }

  .menu-items {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    flex-direction: column;
    align-items: center;
    background: rgb(255, 255, 255);
    padding: 1rem 0;
    gap: 1rem;
    transform: translateY(-100%);
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s ease;
  }

  .menu-items.open {
    transform: translateY(0);
    opacity: 1;
    pointer-events: all;
  }
}
