.download-container {
  padding: 4rem 5%;
}

.download-content {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  align-items: center;
}

.app-preview {
  text-align: center;
}

.app-preview img {
  max-width: 100%;
  height: auto;
}

.download-options {
  padding: 2rem;
}

.download-options h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  text-align: left;
}

.download-options p {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 2rem;
}

.store-buttons {
  display: flex;
  gap: 1rem;
  margin-bottom: 3rem;
}

.store-button {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem 2rem;
  border-radius: 12px;
  text-decoration: none;
  color: white;
  transition: transform 0.3s ease;
}

.store-button:hover {
  transform: translateY(-2px);
}

.store-button.apple {
  background: #000;
}

.store-button.google {
  background: #414141;
}

.store-button i {
  font-size: 2rem;
  background: none;
  -webkit-text-fill-color: white;
}

.button-text {
  display: flex;
  flex-direction: column;
}

.button-text span {
  font-size: 0.8rem;
}

.button-text strong {
  font-size: 1.2rem;
}

.app-features {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
}

.feature {
  text-align: center;
  padding: 1.5rem;
  background: white;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
}

.feature i {
  font-size: 2rem;
  margin-bottom: 1rem;
  background: linear-gradient(90deg, #8c30f5 0%, #b066ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.feature h3 {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  color: #333;
}

.feature p {
  font-size: 1rem;
  color: #666;
  margin: 0;
}

@media (max-width: 968px) {
  .download-content {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .app-preview {
    order: 2;
  }

  .download-options {
    order: 1;
    padding: 1rem;
  }

  .store-buttons {
    flex-direction: column;
  }

  .store-button {
    width: 100%;
    justify-content: center;
  }
}
