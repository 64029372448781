.contact-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 5%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.contact-form-section,
.faq-section {
  background: white;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form-group label {
  font-weight: 500;
  color: #333;
}

.form-group input,
.form-group select,
.form-group textarea {
  padding: 0.8rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  transition: border-color 0.3s ease;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
  border-color: #8c30f5;
  outline: none;
}

.submit-button {
  padding: 1rem 2rem;
  font-size: 1.1rem;
  border: none;
  border-radius: 30px;
  background: linear-gradient(90deg, #00f2fe 0%, #4facfe 100%);
  color: white;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.submit-button:hover {
  transform: translateY(-2px);
}

.faq-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.faq-item {
  border-radius: 8px;
  overflow: hidden;
}

.faq-question {
  width: 100%;
  padding: 1rem;
  background: #f8f9fa;
  border: none;
  text-align: left;
  font-size: 1.1rem;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.3s ease;
}

.faq-question:hover {
  background: #e9ecef;
}

.faq-answer {
  padding: 1rem;
  background: white;
  border: 1px solid #e9ecef;
  border-top: none;
}

.live-chat-widget {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 1000;
}

.chat-toggle {
  padding: 1rem 2rem;
  border: none;
  border-radius: 30px;
  background: linear-gradient(90deg, #00f2fe 0%, #4facfe 100%);
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1.1rem;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
}

.chat-toggle:hover {
  transform: translateY(-2px);
}

.chat-toggle i {
  font-size: 1.2rem;
}

@media (max-width: 768px) {
  .contact-container {
    padding: 1rem;
    grid-template-columns: 1fr;
  }

  .faq-section {
    padding: 0;
    margin: 0%;
    background: transparent;
    box-shadow: none;
  }

  .faq-item {
    padding: 0px !important;
    background-color: #ddd;
  }

  .form-group input,
  .form-group select,
  .form-group textarea {
    padding: 0.7rem;
    font-size: 16px; /* Prevents zoom on iOS */
  }

  .submit-button {
    width: 100%;
    padding: 0.8rem;
  }

  .faq-question {
    padding: 0.8rem;
    font-size: 1rem;
    background-color: #fff;
  }

  .faq-question i {
    font-size: 20px !important;
  }

  .faq-answer {
    padding: 0.8rem;
  }

  .live-chat-widget {
    bottom: 1rem;
    right: 1rem;
  }

  .chat-toggle {
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
  }
}
