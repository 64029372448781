.provider-hero {
  padding: 4rem 5%;
  text-align: center;
}

.provider-card {
  background: white;
  padding: 3rem;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  max-width: 1200px;
  margin: 0 auto;
}

.provider-card h2 {
  font-size: 2.2rem;
  margin-bottom: 3rem;
  background: linear-gradient(90deg, #8c30f5 0%, #b066ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
}

.feature-item {
  padding: 2rem;
  background: linear-gradient(
    135deg,
    rgba(140, 48, 245, 0.03) 0%,
    rgba(0, 242, 254, 0.03) 100%
  );
  border-radius: 15px;
  text-align: left;
}

.feature-item i {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  background: linear-gradient(90deg, #00f2fe 0%, #4facfe 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.feature-item h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #1a1a1a;
}

.feature-item p {
  color: #666;
  line-height: 1.6;
}

.workflow-section {
  padding: 4rem 5%;
  background: linear-gradient(
    135deg,
    rgba(140, 48, 245, 0.03) 0%,
    rgba(0, 242, 254, 0.03) 100%
  );
}

.workflow-section h2 {
  text-align: center;
  font-size: 2.2rem;
  margin-bottom: 3rem;
  background: linear-gradient(90deg, #8c30f5 0%, #b066ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.workflow-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.workflow-item {
  background: white;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
}

.workflow-item i {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  background: linear-gradient(90deg, #00f2fe 0%, #4facfe 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.workflow-item h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #1a1a1a;
}

.workflow-item ul {
  list-style: none;
  padding: 0;
}

.workflow-item li {
  padding: 0.5rem 0;
  color: #666;
  position: relative;
  padding-left: 1.5rem;
}

.workflow-item li:before {
  content: "•";
  position: absolute;
  left: 0;
  color: #4facfe;
}

.integration-section {
  padding: 4rem 5%;
}

.integration-section h2 {
  text-align: center;
  font-size: 2.2rem;
  margin-bottom: 3rem;
  background: linear-gradient(90deg, #8c30f5 0%, #b066ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.integration-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
  max-width: 1200px;
  margin: 0 auto;
}

.integration-info {
  background: white;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
}

.integration-info h3 {
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
  color: #1a1a1a;
}

.integration-info ul {
  list-style: none;
  padding: 0;
  margin-bottom: 2rem;
}

.integration-info li {
  padding: 0.5rem 0;
  color: #666;
}

.integration-button {
  padding: 1rem 2rem;
  font-size: 1.1rem;
  border: none;
  border-radius: 30px;
  background: linear-gradient(90deg, #00f2fe 0%, #4facfe 100%);
  color: white;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.integration-button:hover {
  transform: translateY(-2px);
}

.integration-features {
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  gap: 1.5rem;
}

.integration-feature {
  background: white;
  padding: 1.5rem;
  border-radius: 15px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
}

.integration-feature i {
  font-size: 2rem;
  margin-bottom: 1rem;
  background: linear-gradient(90deg, #00f2fe 0%, #4facfe 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.integration-feature h4 {
  font-size: 1.3rem;
  margin-bottom: 0.5rem;
  color: #1a1a1a;
}

.integration-feature p {
  color: #666;
}

.pricing-section {
  padding: 4rem 5%;
  background: linear-gradient(
    135deg,
    rgba(140, 48, 245, 0.03) 0%,
    rgba(0, 242, 254, 0.03) 100%
  );
}

.pricing-section h2 {
  text-align: center;
  font-size: 2.2rem;
  margin-bottom: 3rem;
  background: linear-gradient(90deg, #8c30f5 0%, #b066ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.pricing-tiers {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.pricing-tier {
  background: white;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
  position: relative;
  text-align: center;
}

.pricing-tier.featured {
  transform: scale(1.05);
  border: 2px solid #4facfe;
}

.featured-label {
  position: absolute;
  top: -12px;
  left: 50%;
  transform: translateX(-50%);
  background: linear-gradient(90deg, #00f2fe 0%, #4facfe 100%);
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-size: 0.9rem;
}

.pricing-tier h3 {
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: #1a1a1a;
}

.price {
  font-size: 2.5rem;
  font-weight: bold;
  color: #1a1a1a;
  margin: 1.5rem 0;
}

.price span {
  font-size: 1rem;
  color: #666;
}

.pricing-tier ul {
  list-style: none;
  padding: 0;
  margin: 2rem 0;
  text-align: left;
}

.pricing-tier li {
  padding: 0.5rem 0;
  color: #666;
  position: relative;
  padding-left: 1.5rem;
}

.pricing-tier li:before {
  content: "✓";
  position: absolute;
  left: 0;
  color: #4facfe;
}

.tier-button {
  padding: 1rem 2rem;
  font-size: 1.1rem;
  border: none;
  border-radius: 30px;
  background: linear-gradient(90deg, #00f2fe 0%, #4facfe 100%);
  color: white;
  cursor: pointer;
  transition: transform 0.3s ease;
  width: 100%;
}

.tier-button:hover {
  transform: translateY(-2px);
}

@media (max-width: 768px) {
  .features-grid,
  .workflow-grid,
  .integration-content,
  .pricing-tiers {
    grid-template-columns: 1fr;
  }

  .pricing-tier.featured {
    transform: none;
  }

  .integration-feature {
    padding: 1rem;
    text-align: center;
  }

  .provider-card .feature-item {
    padding: 2rem;
    text-align: center !important;
  }

  .workflow-item {
    text-align: center;
  }

  .workflow-item ul {
    text-align: left;
  }

  .provider-card h2 {
    font-size: 1.8rem;
  }
}
