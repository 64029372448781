.pricing-hero {
  padding: 4rem 5%;
  text-align: center;
}

.pricing-card {
  background: white;
  padding: 3rem;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: 0 auto;
}

.pricing-card h2 {
  font-size: 2rem;
  margin-bottom: 1.5rem;
  background: linear-gradient(90deg, #8c30f5 0%, #b066ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.price {
  font-size: 4rem;
  font-weight: bold;
  color: #1a1a1a;
  margin: 1rem 0;
}

.forever {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 2rem;
}

.features-list {
  list-style: none;
  padding: 0;
  margin: 2rem 0;
  text-align: left;
}

.features-list li {
  padding: 0.8rem 0;
  color: #666;
  display: flex;
  align-items: center;
}

.features-list i {
  color: #4facfe;
  margin-right: 1rem;
}

.start-button {
  padding: 1rem 2.5rem;
  font-size: 1.2rem;
  border: none;
  border-radius: 30px;
  background: linear-gradient(90deg, #00f2fe 0%, #4facfe 100%);
  color: white;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.start-button:hover {
  transform: translateY(-2px);
}

.pricing-explanation {
  padding: 4rem 5%;
  background: linear-gradient(
    135deg,
    rgba(140, 48, 245, 0.03) 0%,
    rgba(0, 242, 254, 0.03) 100%
  );
}

.pricing-explanation h2 {
  text-align: center;
  font-size: 2.2rem;
  margin-bottom: 3rem;
  background: linear-gradient(90deg, #8c30f5 0%, #b066ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.explanation-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.explanation-item {
  background: white;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
}

.explanation-item i {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  background: linear-gradient(90deg, #00f2fe 0%, #4facfe 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.explanation-item h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #1a1a1a;
}

.explanation-item p {
  color: #666;
  line-height: 1.6;
}

.pricing-faq {
  padding: 4rem 5%;
}

@media screen and (max-width: 768px) {
  .pricing-faq .faq-item {
    padding: 10px !important;
  }

  .features-list i {
    font-size: 1.5rem;
  }
}

.pricing-faq h2 {
  text-align: center;
  font-size: 2.2rem;
  margin-bottom: 3rem;
  background: linear-gradient(90deg, #8c30f5 0%, #b066ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.faq-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.faq-item {
  background: white;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
}

.faq-item h3 {
  font-size: 1.3rem;
  margin-bottom: 1rem;
  color: #1a1a1a;
}

.faq-item p {
  color: #666;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .explanation-grid,
  .faq-grid {
    grid-template-columns: 1fr;
  }

  .pricing-card {
    padding: 2rem;
  }

  .price {
    font-size: 3rem;
  }
}
