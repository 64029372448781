.api-overview {
  padding: 4rem 5%;
}

.api-card {
  background: white;
  padding: 3rem;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0,0,0,0.1);
  max-width: 1200px;
  margin: 0 auto;
}

.api-card h2 {
  font-size: 2.2rem;
  margin-bottom: 3rem;
  background: linear-gradient(90deg, #8c30f5 0%, #b066ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.api-features-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
}

.api-feature {
  padding: 2rem;
  background: linear-gradient(135deg, rgba(140, 48, 245, 0.03) 0%, rgba(0, 242, 254, 0.03) 100%);
  border-radius: 15px;
  text-align: left;
}

.api-feature i {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  background: linear-gradient(90deg, #00f2fe 0%, #4facfe 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.api-feature h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #1a1a1a;
}

.api-feature p {
  color: #666;
  line-height: 1.6;
}

.integration-guides {
  padding: 4rem 5%;
  background: linear-gradient(135deg, rgba(140, 48, 245, 0.03) 0%, rgba(0, 242, 254, 0.03) 100%);
}

.integration-guides h2 {
  text-align: center;
  font-size: 2.2rem;
  margin-bottom: 3rem;
  background: linear-gradient(90deg, #8c30f5 0%, #b066ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.guides-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.guide-item {
  background: white;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 5px 20px rgba(0,0,0,0.05);
}

.guide-item h3 {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  color: #1a1a1a;
}

.code-block {
  background: #1a1a1a;
  padding: 1.5rem;
  border-radius: 10px;
  margin: 1rem 0;
  overflow-x: auto;
}

.code-block code {
  color: #fff;
  font-family: 'Courier New', Courier, monospace;
}

.guide-item ul {
  list-style: none;
  padding: 0;
  margin-top: 1.5rem;
}

.guide-item li {
  padding: 0.5rem 0;
  color: #666;
  position: relative;
  padding-left: 1.5rem;
}

.guide-item li:before {
  content: "•";
  position: absolute;
  left: 0;
  color: #4facfe;
}

.sdk-section {
  padding: 4rem 5%;
}

.sdk-section h2 {
  text-align: center;
  font-size: 2.2rem;
  margin-bottom: 3rem;
  background: linear-gradient(90deg, #8c30f5 0%, #b066ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.sdk-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.sdk-item {
  background: white;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 5px 20px rgba(0,0,0,0.05);
  text-align: center;
}

.sdk-item i {
  font-size: 3rem;
  margin-bottom: 1rem;
  background: linear-gradient(90deg, #00f2fe 0%, #4facfe 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.sdk-item h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #1a1a1a;
}

.api-reference {
  padding: 4rem 5%;
  background: linear-gradient(135deg, rgba(140, 48, 245, 0.03) 0%, rgba(0, 242, 254, 0.03) 100%);
}

.api-reference h2 {
  text-align: center;
  font-size: 2.2rem;
  margin-bottom: 3rem;
  background: linear-gradient(90deg, #8c30f5 0%, #b066ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.endpoint-list {
  max-width: 1200px;
  margin: 0 auto;
}

.endpoint {
  background: white;
  padding: 1.5rem;
  border-radius: 10px;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.method {
  padding: 0.5rem 1rem;
  border-radius: 5px;
  font-weight: bold;
  font-family: 'Courier New', Courier, monospace;
}

.get { background: #61affe; color: white; }
.post { background: #49cc90; color: white; }
.put { background: #fca130; color: white; }
.delete { background: #f93e3e; color: white; }

.endpoint code {
  font-family: 'Courier New', Courier, monospace;
  color: #1a1a1a;
}

.endpoint p {
  color: #666;
  margin: 0;
}

.api-docs-link {
  text-align: center;
  margin-top: 2rem;
}

.api-docs-link a {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  color: #4facfe;
  text-decoration: none;
  font-weight: bold;
}

.api-docs-link i {
  font-size: 1.2rem;
}

.developer-resources {
  padding: 4rem 5%;
}

.developer-resources h2 {
  text-align: center;
  font-size: 2.2rem;
  margin-bottom: 3rem;
  background: linear-gradient(90deg, #8c30f5 0%, #b066ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.resources-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.resource-item {
  background: white;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 5px 20px rgba(0,0,0,0.05);
  text-align: center;
}

.resource-item i {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  background: linear-gradient(90deg, #00f2fe 0%, #4facfe 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.resource-item h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #1a1a1a;
}

.resource-item p {
  color: #666;
  line-height: 1.6;
  margin-bottom: 1.5rem;
}

.resource-link {
  display: inline-block;
  padding: 0.8rem 1.5rem;
  background: linear-gradient(90deg, #00f2fe 0%, #4facfe 100%);
  color: white;
  text-decoration: none;
  border-radius: 25px;
  transition: transform 0.3s ease;
}

.resource-link:hover {
  transform: translateY(-2px);
}

@media (max-width: 1024px) {
  .sdk-grid,
  .resources-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .api-features-grid,
  .guides-grid,
  .sdk-grid,
  .resources-grid {
    grid-template-columns: 1fr;
  }

  .api-card,
  .guide-item,
  .sdk-item,
  .resource-item {
    padding: 1.5rem;
  }

  .endpoint {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }
}
