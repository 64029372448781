.team-member-profile {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
}

.profile-header {
  padding-top: 20px;
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-bottom: 3rem;
}

.profile-image {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  overflow: hidden;
  background: linear-gradient(
    135deg,
    rgba(140, 48, 245, 0.1) 0%,
    rgba(0, 242, 254, 0.1) 100%
  );
  padding: 8px;
}

.profile-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.profile-info h1 {
  font-size: 2.5rem;
  color: #1a1a1a;
  margin-bottom: 0.5rem;
}

.profile-info h2 {
  font-size: 1.5rem;
  color: #8c30f5;
}

.profile-content {
  display: grid;
  gap: 2rem;
}

.bio-section,
.contact-section {
  background: white;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.bio-section h3,
.contact-section h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #1a1a1a;
}

.contact-details {
  margin-bottom: 1.5rem;
}

.contact-details p {
  margin: 0.5rem 0;
  font-size: 1.1rem;
}

.social-links {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.social-links a {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  background: #f5f5f5;
  border-radius: 8px;
  color: #1a1a1a;
  text-decoration: none;
  transition: all 0.3s ease;
  flex: 1;
  min-width: 200px;
  justify-content: center;
}

.social-links a i {
  padding-top: 18px;
  font-size: 1.5rem;
}

.social-links a:hover {
  background: #8c30f5;
  color: white;
}

.add-contact-btn,
.book-call-btn {
  background: #8c30f5 !important;
  color: white !important;
}

.add-contact-btn:hover,
.book-call-btn:hover {
  background: #7020d9 !important;
  transform: translateY(-2px);
}

.book-call-btn {
  background: #2ecc71 !important;
}

.book-call-btn:hover {
  background: #27ae60 !important;
}

.digital-card-btn {
  background: #3498db !important;
  color: white !important;
}

.digital-card-btn:hover {
  background: #2980b9 !important;
}

@media (max-width: 768px) {
  .profile-header {
    flex-direction: column;
    text-align: center;
  }

  .profile-image {
    width: 150px;
    height: 150px;
    margin: 0 auto;
  }

  .social-links {
    flex-direction: column;
  }
}
