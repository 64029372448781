
.feature-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  padding: 4rem 5%;
  max-width: 1400px;
  margin: 0 auto;
}

.feature-card {
  background: white;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0,0,0,0.1);
  text-align: center;
}

.feature-card i {
  font-size: 3rem;
  margin-bottom: 1.5rem;
  background: linear-gradient(90deg, #00f2fe 0%, #4facfe 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.feature-card h2 {
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: #1a1a1a;
}

.feature-card p {
  color: #666;
  line-height: 1.6;
}

.instant-checkin-detail {
  padding: 4rem 5%;
  background: linear-gradient(135deg, rgba(140, 48, 245, 0.03) 0%, rgba(0, 242, 254, 0.03) 100%);
  border-radius: 20px;
  margin: 4rem auto;
  max-width: 1400px;
}

.instant-checkin-detail h2 {
  text-align: center;
  font-size: 2.2rem;
  margin-bottom: 3rem;
  background: linear-gradient(90deg, #8c30f5 0%, #b066ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.instant-checkin-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  margin-bottom: 3rem;
}

.instant-checkin-item {
  background: white;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 5px 20px rgba(0,0,0,0.05);
}

.instant-checkin-item i {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  background: linear-gradient(90deg, #00f2fe 0%, #4facfe 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.instant-checkin-item h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #1a1a1a;
}

.instant-checkin-item p {
  color: #666;
  line-height: 1.6;
}

.instant-checkin-features {
  background: white;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 5px 20px rgba(0,0,0,0.05);
}

.instant-checkin-features h3 {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  color: #1a1a1a;
}

.instant-checkin-features ul {
  list-style: none;
  padding: 0;
}

.instant-checkin-features li {
  padding: 0.8rem 0;
  color: #666;
  position: relative;
  padding-left: 2rem;
}

.instant-checkin-features li:before {
  content: "✓";
  position: absolute;
  left: 0;
  color: #4facfe;
  font-weight: bold;
}

.unified-identity-detail,
.secure-auth-detail {
  padding: 4rem 5%;
  background: linear-gradient(135deg, rgba(140, 48, 245, 0.03) 0%, rgba(0, 242, 254, 0.03) 100%);
  border-radius: 20px;
  margin: 4rem auto;
  max-width: 1400px;
}

.unified-identity-detail h2,
.secure-auth-detail h2 {
  text-align: center;
  font-size: 2.2rem;
  margin-bottom: 3rem;
  background: linear-gradient(90deg, #8c30f5 0%, #b066ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.unified-identity-grid,
.secure-auth-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  margin-bottom: 3rem;
}

.unified-identity-item,
.secure-auth-item {
  background: white;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 5px 20px rgba(0,0,0,0.05);
}

.unified-identity-item i,
.secure-auth-item i {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  background: linear-gradient(90deg, #00f2fe 0%, #4facfe 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.unified-identity-item h3,
.secure-auth-item h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #1a1a1a;
}

.unified-identity-item p,
.secure-auth-item p {
  color: #666;
  line-height: 1.6;
}

.unified-identity-features,
.secure-auth-features {
  background: white;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 5px 20px rgba(0,0,0,0.05);
}

.unified-identity-features h3,
.secure-auth-features h3 {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  color: #1a1a1a;
}

.unified-identity-features ul,
.secure-auth-features ul {
  list-style: none;
  padding: 0;
}

.unified-identity-features li,
.secure-auth-features li {
  padding: 0.8rem 0;
  color: #666;
  position: relative;
  padding-left: 2rem;
}

.unified-identity-features li:before,
.secure-auth-features li:before {
  content: "✓";
  position: absolute;
  left: 0;
  color: #4facfe;
  font-weight: bold;
}

.features-cta {
  text-align: center;
  padding: 4rem 5%;
  background: linear-gradient(135deg, rgba(140, 48, 245, 0.05) 0%, rgba(0, 242, 254, 0.05) 100%);
  border-radius: 20px;
  margin-top: 4rem;
}

.features-cta h2 {
  font-size: 2rem;
  margin-bottom: 2rem;
  background: linear-gradient(90deg, #8c30f5 0%, #b066ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.features-cta .cta-button {
  padding: 1rem 2.5rem;
  font-size: 1.2rem;
  border: none;
  border-radius: 30px;
  background: linear-gradient(90deg, #00f2fe 0%, #4facfe 100%);
  color: white;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.features-cta .cta-button:hover {
  transform: translateY(-2px);
}

.care-circle-detail {
  padding: 4rem 5%;
  background: linear-gradient(135deg, rgba(140, 48, 245, 0.03) 0%, rgba(0, 242, 254, 0.03) 100%);
  border-radius: 20px;
  margin: 4rem auto;
  max-width: 1400px;
}

.care-circle-detail h2 {
  text-align: center;
  font-size: 2.2rem;
  margin-bottom: 3rem;
  background: linear-gradient(90deg, #8c30f5 0%, #b066ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.care-circle-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  margin-bottom: 3rem;
}

.care-circle-item {
  background: white;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 5px 20px rgba(0,0,0,0.05);
}

.care-circle-item i {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  background: linear-gradient(90deg, #00f2fe 0%, #4facfe 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.care-circle-item h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #1a1a1a;
}

.care-circle-item p {
  color: #666;
  line-height: 1.6;
}

.care-circle-features {
  background: white;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 5px 20px rgba(0,0,0,0.05);
}

.care-circle-features h3 {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  color: #1a1a1a;
}

.care-circle-features ul {
  list-style: none;
  padding: 0;
}

.care-circle-features li {
  padding: 0.8rem 0;
  color: #666;
  position: relative;
  padding-left: 2rem;
}

.care-circle-features li:before {
  content: "✓";
  position: absolute;
  left: 0;
  color: #4facfe;
  font-weight: bold;
}

.unified-records-detail {
  padding: 4rem 5%;
  background: linear-gradient(135deg, rgba(140, 48, 245, 0.03) 0%, rgba(0, 242, 254, 0.03) 100%);
  border-radius: 20px;
  margin: 4rem auto;
  max-width: 1400px;
}

.unified-records-detail h2 {
  text-align: center;
  font-size: 2.2rem;
  margin-bottom: 3rem;
  background: linear-gradient(90deg, #8c30f5 0%, #b066ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.unified-records-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  margin-bottom: 3rem;
}

.unified-records-item {
  background: white;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 5px 20px rgba(0,0,0,0.05);
}

.unified-records-item i {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  background: linear-gradient(90deg, #00f2fe 0%, #4facfe 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.unified-records-item h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #1a1a1a;
}

.unified-records-item p {
  color: #666;
  line-height: 1.6;
}

.unified-records-features {
  background: white;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 5px 20px rgba(0,0,0,0.05);
}

.unified-records-features h3 {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  color: #1a1a1a;
}

.unified-records-features ul {
  list-style: none;
  padding: 0;
}

.unified-records-features li {
  padding: 0.8rem 0;
  color: #666;
  position: relative;
  padding-left: 2rem;
}

.unified-records-features li:before {
  content: "✓";
  position: absolute;
  left: 0;
  color: #4facfe;
  font-weight: bold;
}

.family-history-detail {
  padding: 4rem 5%;
  background: linear-gradient(135deg, rgba(140, 48, 245, 0.03) 0%, rgba(0, 242, 254, 0.03) 100%);
  border-radius: 20px;
  margin: 4rem auto;
  max-width: 1400px;
}

.family-history-detail h2 {
  text-align: center;
  font-size: 2.2rem;
  margin-bottom: 3rem;
  background: linear-gradient(90deg, #8c30f5 0%, #b066ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.family-history-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  margin-bottom: 3rem;
}

.family-history-item {
  background: white;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 5px 20px rgba(0,0,0,0.05);
}

.family-history-item i {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  background: linear-gradient(90deg, #00f2fe 0%, #4facfe 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.family-history-item h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #1a1a1a;
}

.family-history-item p {
  color: #666;
  line-height: 1.6;
}

.family-history-features {
  background: white;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 5px 20px rgba(0,0,0,0.05);
}

.family-history-features h3 {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  color: #1a1a1a;
}

.family-history-features ul {
  list-style: none;
  padding: 0;
}

.family-history-features li {
  padding: 0.8rem 0;
  color: #666;
  position: relative;
  padding-left: 2rem;
}

.family-history-features li:before {
  content: "✓";
  position: absolute;
  left: 0;
  color: #4facfe;
  font-weight: bold;
}

.control-benefits-detail {
  padding: 4rem 5%;
  background: linear-gradient(135deg, rgba(140, 48, 245, 0.03) 0%, rgba(0, 242, 254, 0.03) 100%);
  border-radius: 20px;
  margin: 4rem auto;
  max-width: 1400px;
}

.control-benefits-detail h2 {
  text-align: center;
  font-size: 2.2rem;
  margin-bottom: 3rem;
  background: linear-gradient(90deg, #8c30f5 0%, #b066ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.control-benefits-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  margin-bottom: 3rem;
}

.control-benefits-item {
  background: white;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 5px 20px rgba(0,0,0,0.05);
}

.control-benefits-item i {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  background: linear-gradient(90deg, #00f2fe 0%, #4facfe 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.control-benefits-item h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #1a1a1a;
}

.control-benefits-item p {
  color: #666;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .control-benefits-grid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 768px) {
  .family-history-grid {
    grid-template-columns: 1fr;
  }
  .unified-records-grid,
  .care-circle-grid,
  .instant-checkin-grid,
  .unified-identity-grid,
  .secure-auth-grid {
    grid-template-columns: 1fr;
  }
  .features-hero h1 {
    font-size: 2.5rem;
  }

  .feature-cards {
    grid-template-columns: 1fr;
    padding: 2rem;
  }

  .features-cta h2 {
    font-size: 1.8rem;
  }
}
