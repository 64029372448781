.signup-section {
  padding: 2rem 5%;
}

.signup-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  max-width: 1200px;
  margin: 0 auto;
  background: white;
  padding: 3rem;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0,0,0,0.1);
}

.signup-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form-group label {
  font-weight: 500;
  color: #1a1a1a;
}

.form-group input:not([type="checkbox"]) {
  padding: 0.8rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  transition: border-color 0.3s ease;
}

.form-group input:focus {
  border-color: #8c30f5;
  outline: none;
}

.form-group.checkbox {
  flex-direction: row;
  align-items: center;
  gap: 0.8rem;
}

.form-group.checkbox label {
  font-size: 0.9rem;
  color: #666;
}

.form-group.checkbox a {
  color: #8c30f5;
  text-decoration: none;
}

.password-requirements {
  margin-top: 0.5rem;
  font-size: 0.85rem;
  color: #666;
}

.password-requirements ul {
  list-style: none;
  padding-left: 1rem;
  margin-top: 0.3rem;
}

.password-requirements li {
  position: relative;
  padding-left: 1.2rem;
}

.password-requirements li:before {
  content: "•";
  position: absolute;
  left: 0;
  color: #8c30f5;
}

.signup-button {
  padding: 1rem;
  background: linear-gradient(90deg, #8c30f5 0%, #b066ff 100%);
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: transform 0.3s ease;
  margin-top: 1rem;
}

.signup-button:hover {
  transform: translateY(-2px);
}

.login-link {
  text-align: center;
  margin-top: 1.5rem;
  font-size: 0.9rem;
  color: #666;
}

.login-link a {
  color: #8c30f5;
  text-decoration: none;
  font-weight: 500;
}

.signup-features {
  padding: 2rem;
  background: linear-gradient(135deg, rgba(140, 48, 245, 0.03) 0%, rgba(0, 242, 254, 0.03) 100%);
  border-radius: 15px;
}

.signup-features h3 {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  color: #1a1a1a;
}

.signup-features ul {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.signup-features li {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: #666;
}

.signup-features i {
  font-size: 1.2rem;
  background: linear-gradient(90deg, #00f2fe 0%, #4facfe 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media (max-width: 768px) {
  .signup-container {
    grid-template-columns: 1fr;
    padding: 2rem;
    gap: 2rem;
  }

  .signup-features {
    padding: 1.5rem;
  }
}
