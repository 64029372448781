/* Global Styles */
.homepage {
  font-family: "Inter", sans-serif;
  color: #333;
  overflow-x: hidden;
  background: linear-gradient(135deg, #fff 0%, #f6f9fc 100%);
  position: relative;
  perspective: 1px;
  transform-style: preserve-3d;
}

.homepage::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(
      circle at 30% 30%,
      rgba(140, 48, 245, 0.05) 0%,
      transparent 60%
    ),
    radial-gradient(
      circle at 70% 70%,
      rgba(0, 242, 254, 0.05) 0%,
      transparent 60%
    );
  pointer-events: none;
  z-index: 0;
}

/* Hero Section */
.hero {
  min-height: 100vh;
  padding: 80px 5% 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: #333;
  position: relative;
  overflow: hidden;
  text-align: left;
}

.hero-image-container {
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
}

.hero-image {
  border-radius: 20px;
  height: 70vh !important;
  animation: float 6s ease-in-out infinite;
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0px);
  }
}

.hero-content {
  flex: 1;
  max-width: 100%;
  position: relative;
  z-index: 2;
  padding: 2.5rem;
  margin-left: 5%;
}

@media screen and (max-width: 768px) {
  .hero-content {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .mission-item {
    text-align: center;
  }
}

.hero h1 {
  font-size: 3.5rem;
  margin-bottom: 1.5rem;
  background: linear-gradient(90deg, #8c30f5 0%, #b066ff 100%, #c19eff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% auto;
  line-height: 1.1;
  font-weight: 800;
  letter-spacing: -0.02em;
}

@keyframes gradient {
  0% {
    background-position: 0% center;
  }
  25% {
    background-position: 50% center;
  }
  50% {
    background-position: 100% center;
  }
  75% {
    background-position: 150% center;
  }
  100% {
    background-position: 200% center;
  }
}

.cta-button {
  padding: 1.2rem 2.5rem;
  font-size: 1.2rem;
  border: none;
  border-radius: 30px;
  background: linear-gradient(90deg, #00f2fe 0%, #4facfe 100%);
  color: white;
  cursor: pointer;
  transition: transform 0.3s ease;
  margin-top: 2rem;
}

.cta-button:hover {
  transform: translateY(-2px);
}

/* Section Styles */
section {
  padding: 6rem 0;
  width: 100%;
  position: relative;
  overflow: hidden;
}

h2 {
  text-align: center !important;
  font-size: 2.5rem;
  margin-bottom: 3rem;
  color: #1a1a1a;
}

/* Logo Wall */
.logo-wall {
  padding: 4rem 0;
  background: #fff;
  position: relative;
}

.logo-wall::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(
      circle at 30% 30%,
      rgba(140, 48, 245, 0.03) 0%,
      transparent 60%
    ),
    radial-gradient(
      circle at 70% 70%,
      rgba(0, 242, 254, 0.03) 0%,
      transparent 60%
    );
}

.logo-wall-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  gap: 2rem;
  padding: 0 5%;
}

.logo-item {
  flex: 0 1 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: grayscale(100%);
  opacity: 0.6;
  transition: all 0.3s ease;
}

.logo-item:hover {
  filter: grayscale(0%);
  opacity: 1;
}

.logo-item img {
  max-width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .logo-wall {
    padding: 2rem 0;
  }

  .logo-item {
    flex: 0 1 150px;
  }
}

/* Mission Section */
.mission-sections {
  background: #fff;
  position: relative;
  padding: 6rem 0;
  overflow: hidden;
}

.mission-content {
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 2rem;
  position: relative;
  z-index: 2;
}

.mission-intro {
  text-align: center;
  margin-bottom: 4rem;
}

.mission-intro p {
  font-size: 1.25rem;
  line-height: 1.8;
  color: #666;
  max-width: 800px;
  margin: 0 auto;
}

.mission-item {
  margin-bottom: 4rem;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease;
}

.mission-item:hover {
  transform: translateY(-5px);
}

.mission-item i {
  font-size: 2rem;
  margin-bottom: 1rem;
  background: linear-gradient(90deg, #8c30f5, #b066ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}

.mission-item h3 {
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
  color: #1a1a1a;
  background: linear-gradient(90deg, #8c30f5, #b066ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.mission-item p {
  font-size: 1.1rem;
  line-height: 1.8;
  color: #666;
  margin: 0;
}

@media (max-width: 768px) {
  .mission-sections {
    padding: 4rem 0;
  }

  .mission-item {
    padding: 1.5rem;
    margin-bottom: 2rem;
  }

  .mission-intro p {
    font-size: 1.1rem;
  }

  .mission-item h3 {
    font-size: 1.5rem;
  }

  .mission-item p {
    font-size: 1rem;
  }
}

.line1 {
  background-image: url("/public/images/line1.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom right;
}

.line2 {
  background-image: url("/public/images/line2.png");
  background-size: contain;
  background-repeat: repeat-x;
  background-position: top center;
}

.line3 {
  background-image: url("/public/images/line3.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom left;
}

.line4 {
  background-image: url("/public/images/line4.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top left;
}

.line5 {
  background-image: url("/public/images/line5.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top right;
}

/* Explainer Video Section */
.explainer-video {
  text-align: center;
  position: relative;
  overflow: hidden;
}
.disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.3;
}

.video-container {
  position: relative;
  height: 400px;
  max-width: 600px;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 20px;
  box-shadow: 0 20px 40px rgba(140, 48, 245, 0.1);
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 400px;
}

/* How It Works Section */
.how-it-works {
  position: relative;
  padding: 6rem 0;
}

.steps-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem;
  padding: 0 5%;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
}

.step {
  text-align: center;
  padding: 3rem 2rem;
  background: linear-gradient(145deg, #fff, #f6f9fc);
  border-radius: 30px;
  box-shadow: 20px 20px 60px rgba(0, 0, 0, 0.05),
    -20px -20px 60px rgba(255, 255, 255, 0.8);
  transition: all 0.4s ease;
  position: relative;
  overflow: hidden;
}

.step::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: linear-gradient(90deg, #8c30f5, #b066ff);
  opacity: 0;
  transition: opacity 0.4s ease;
}

.step:hover {
  transform: translateY(-10px);
}

.step:hover::before {
  opacity: 1;
}

.step h3 {
  font-size: 1.5rem;
  margin: 1.5rem 0;
  color: #1a1a1a;
}

.step p {
  color: #666;
  line-height: 1.6;
  font-size: 1.1rem;
}

.step i {
  font-size: 2.5rem;
  background: linear-gradient(90deg, #8c30f5, #b066ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Network Section */
.network {
  position: relative;
  padding: 6rem 0;
  text-align: center;
}

.network-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 5%;
  position: relative;
  z-index: 2;
}

.network-stats {
  display: flex;
  justify-content: center;
  gap: 4rem;
  margin: 3rem 0;
}

.stat-item {
  text-align: center;
}

.stat-number {
  font-size: 62px !important;
  font-weight: 800;
  background: linear-gradient(90deg, #8c30f5, #b066ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 0.5rem;
}

.stat-label {
  font-size: 1.2rem;
  color: #666;
  max-width: 200px;
}

@media (max-width: 768px) {
  .network-stats {
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    text-align: center;
  }

  .stat-item {
    width: 100%;
    max-width: 280px;
  }

  .stat-number {
  }

  .stat-label {
    margin: 0 auto;
  }
}

/* Bottom CTA Section */
.bottom-cta {
  padding: 6rem 0;
  background: linear-gradient(
    135deg,
    rgba(140, 48, 245, 0.05) 0%,
    rgba(0, 242, 254, 0.05) 100%
  );
  text-align: center;
  position: relative;
  overflow: hidden;
}

.bottom-cta::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(140, 48, 245, 0.2),
    transparent
  );
}

.bottom-cta-content {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 2rem;
}

.bottom-cta-buttons {
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 2rem;
}

@media (max-width: 768px) {
  .bottom-cta-buttons {
    flex-direction: column;
    gap: 15px;
    padding: 0 1rem;
  }

  .bottom-cta-buttons .cta-button {
    width: 100%;
    margin: 0;
  }
}

.bottom-cta h2 {
  margin-bottom: 1.5rem;
  background: linear-gradient(90deg, #8c30f5 0%, #b066ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bottom-cta p {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 2rem;
}

/* Footer Section */
.homepage-footer {
  background: #ffffff;
  padding: 2rem 5%;
  border-top: 1px solid rgba(140, 48, 245, 0.1);
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

.footer-links {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 1.5rem;
}

.footer-links a {
  color: #666;
  text-decoration: none;
  font-size: 0.9rem;
  transition: color 0.3s ease;
}

.footer-links a:hover {
  color: #8c30f5;
}

.footer-social {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  margin-bottom: 1.5rem;
}

.footer-social a {
  color: #666;
  transition: color 0.3s ease;
}

.footer-social a:hover {
  color: #8c30f5;
}

.footer-social i {
  font-size: 1rem;
  background: none;
  -webkit-background-clip: initial;
  -webkit-text-fill-color: initial;
}

.footer-bottom {
  color: #666;
  font-size: 0.9rem;
}

@media (max-width: 768px) {
  .footer-links {
    flex-wrap: wrap;
    gap: 1rem;
  }

  .homepage-footer {
    padding: 1.5rem 5%;
  }
}

/* Why Choose Section */
.why-choose {
  padding: 6rem 0;
  position: relative;
  overflow: hidden;
}

.feature {
  background: white;
  padding: 2.5rem;
  border-radius: 20px;
  /* box-shadow: 0 15px 35px rgba(140, 48, 245, 0.1); */
  text-align: center;
  transition: all 0.3s ease;
  border: 1px solid rgba(140, 48, 245, 0.1);
  position: relative;
  overflow: hidden;
}

.feature::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: linear-gradient(90deg, #8c30f5, #b066ff);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.feature:hover::before {
  opacity: 1;
}

.feature-details {
  list-style: none;
  padding: 0;
  margin: 1.5rem 0 0;
  text-align: left;
  border-top: 1px solid rgba(140, 48, 245, 0.1);
  padding-top: 1.5rem;
}

.feature-details li {
  margin: 0.75rem 0;
  padding-left: 1.5rem;
  position: relative;
  color: #666;
  font-size: 0.95rem;
  line-height: 1.4;
}

.feature-details li::before {
  content: "→";
  position: absolute;
  left: 0;
  color: #8c30f5;
}

.features-slider {
  max-width: 1400px;
  margin: 0 auto;
  padding: 10px 5%;
  position: relative;
  z-index: 2;
}

.features-slider .slick-slide {
  padding: 0 1rem;
}

.features-slider .slick-dots {
  bottom: -40px;
}

.features-slider .slick-dots li button:before {
  color: #8c30f5;
}

.features-slider .slick-prev,
.features-slider .slick-next {
  width: 40px;
  height: 40px;
  background: white;
  border-radius: 50%;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.features-slider .slick-prev {
  left: 0;
}

.features-slider .slick-next {
  right: 0;
}

.features-slider .slick-prev:before,
.features-slider .slick-next:before {
  color: #8c30f5;
  opacity: 1;
}

.features-slider .slick-track {
  display: flex;
  align-items: stretch;
  padding: 12px 0;
}

.features-slider .slick-slide > div {
  height: 100%;
}

.step {
  text-align: center;
  padding: 2.5rem 2rem;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px);
  border-radius: 30px;
  box-shadow: 0 20px 40px rgba(140, 48, 245, 0.1);
  transition: all 0.4s ease;
  border: 1px solid rgba(140, 48, 245, 0.1);
  position: relative;
  overflow: hidden;
}

.step-icon {
  width: 80px;
  height: 80px;
  margin: 0 auto 1.5rem;
  background: linear-gradient(135deg, #8c30f5 0%, #b066ff 100%);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease;
}

.step-icon i {
  font-size: 2rem;
  color: white;
  background: none;
  -webkit-background-clip: initial;
  -webkit-text-fill-color: initial;
}

.step:hover .step-icon {
  transform: scale(1.1) rotate(5deg);
}

.step-details {
  list-style: none;
  padding: 0;
  margin: 1.5rem 0 0;
  text-align: left;
}

.step-details li {
  margin: 0.5rem 0;
  padding-left: 1.5rem;
  position: relative;
  color: #666;
  font-size: 0.95rem;
}

.step-details li::before {
  content: "→";
  position: absolute;
  left: 0;
  color: #8c30f5;
}

.feature::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: linear-gradient(90deg, #00f2fe, #4facfe);
  transform: scaleX(0);
  transition: transform 0.4s ease;
}

.feature:hover {
  transform: translateY(-10px);
  border-color: rgba(140, 48, 245, 0.2);
}

.feature:hover::after {
  transform: scaleX(1);
}

.feature h3 {
  font-size: 1.5rem;
  margin: 1.5rem 0;
  color: #1a1a1a;
}

.feature p {
  color: #666;
  line-height: 1.6;
  font-size: 1.1rem;
}

.feature i {
  font-size: 2.5rem;
  background: linear-gradient(90deg, #00f2fe, #4facfe);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Icons */
i {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  background: linear-gradient(90deg, #00f2fe 0%, #4facfe 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .mission-content,
  .features-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }

  .steps-container {
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }
}

@media (max-width: 768px) {
  .steps-container {
    grid-template-columns: 1fr;
  }

  .step {
    padding: 2rem 1.5rem;
  }

  .step-icon {
    width: 60px;
    height: 60px;
  }

  .step-icon i {
    font-size: 1.5rem;
  }
}

@media (max-width: 768px) {
  .hero {
    flex-direction: column;
    text-align: center;
    padding: 2rem;
  }

  .hero-image-container {
    max-width: 100%;
    margin-bottom: 2rem;
    margin-right: 0;
  }

  .hero-image {
  }

  .hero h1 {
    font-size: 2.5rem;
  }

  section {
    padding: 4rem 2rem;
  }

  .mission-content,
  .steps-container,
  .features-grid {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .mission-card,
  .step,
  .feature {
    padding: 2rem;
  }

  .mission-card:hover,
  .step:hover,
  .feature:hover {
    transform: translateY(-5px);
  }
}
